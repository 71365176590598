<template>
  <div>
    <h5>
      Triggers
    </h5>
    <table
      v-if="triggers.length"
      :class="['table kendo-table table-kendo mb-2', { 'is-invalid': this.$validator.errors.has('Triggers') }]"
    >
      <colgroup>
        <col width="35">
      </colgroup>
      <thead>
        <tr>
          <th />
          <th>{{ $t('name') }}</th>
          <th>{{ $t('active') }}</th>
          <th>{{ $t('options') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in triggers"
          :key="`trigger-tableRow-${ index }`"
        >
          <td>{{ index }}.</td>
          <td>{{ item.name }}</td>
          <td>
            <toggle-button
              v-model="item.active"
              :width="50"
              :height="24"
              :font-size="12"
              class="m-0"
            />
          </td>
          <td>
            <div class="btn-group btn-group-sm flex-wrap">
              <button
                class="btn btn-sm btn-primary"
                @click="showSidebar(item)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
              <button
                class="btn btn-sm btn-secondary"
                @click="removeItem(index)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>{{ $t('remove') }}</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <hr v-else>
    <span
      v-show="errors.has('Trigger')"
      class="badge badge-danger"
    >{{ errors.first('Trigger') }}</span>
    <button
      class="btn btn-sm btn-primary float-right"
      @click="addItem()"
    >
      <font-awesome-icon
        class="mr-2"
        icon="plus"
      />
      <span>{{ $t('add') }}</span>
    </button>
    <div class="clearfix" />

    <Sidebar
      :sidebar-width="800"
      :show-sidebar="displaySidebar"
      @close="hideSidebar()"
    >
      <TriggerEditor
        v-if="displaySidebar"
        :trigger="trigger"
        :lane-config="laneConfig"
        @changePtz="changePtz"
        @update="update"
        @close="hideSidebar()"
      />
    </Sidebar>
  </div>
</template>

<script>
import Sidebar from '@/components/Base/Sidebar.vue';

export default {
  name: 'TriggerConfiguration',
  components: {
    TriggerEditor: () => import('./TriggerEditor/TriggerEditor.vue'),
    Sidebar,
  },
  props: {
    triggers: {
      type: Array,
      required: true
    },
    laneConfig: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      displaySidebar: false,
      trigger: null,
    }
  },
  methods: {
    showSidebar (trigger) {
      //Show flow area editor
      this.trigger = trigger;
      this.displaySidebar = true;
    },
    hideSidebar () {
      //Hide flow area editor
      this.trigger = null;
      this.displaySidebar = false;
    },
    addItem () {
      //Get next id from the highest +1
      let maxId = 0;
      this.triggers.map(function (obj) {
        if (obj.id > maxId) {
          maxId = obj.id
        }
      });
      maxId++;

      let trigger = {
        id: maxId,
        active: true,
        name: `Trigger ${ maxId }`,
        delay: 0.5,
        type: 'VirtualStopwatch',
        area: [
          { pan: 10, tilt: 10 },
          { pan: -10, tilt: 10 },
          { pan: -10, tilt: -10 },
          { pan: 10, tilt: -10 }
        ]
      };

      this.triggers.push(trigger);
      this.update();
    },
    removeItem (removeIndex) {
      this.triggers.splice(removeIndex, 1);
      this.update();
    },
    update () {
      this.$emit('update');
    },
    changePtz (position) {
      this.$emit('changePtz', position);
    }
  }
}
</script>